import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import ChooseLangInline from 'src/components/choose-lang-inline/ChooseLangInline';

import * as actions from 'src/store/actions';

import './ChooseLangDialog.scss';
import { getCurrentPageProps } from '../../core/navigation/CurrentRoute';

export const COMPONENT_KEY = 'ChooseLangDialog';
export const CONTAINER_DOM_ID = 'choose-lang-dialog-container';

const DEFAULT_DIALOG_PROPS = {
  // TODO: Export that to data/config/dialogsConfig ?
};

/**
 * Display a dialog (modal) allowing user to change current language for the whole app
 * @param  {object} props
 */
const ChooseLangDialog = ({ supportedLangs, isOpen, labels, actions }) => {
  const currentLangId = labels.id;

  const setPrevIndexMenuItem = () => {
    const valIndex = getCurrentPageProps().indexMenuItem ? getCurrentPageProps().indexMenuItem : 0;
    actions.setIndexMenuItemActive(valIndex);
  }
  /**
   * Actions when user has clicked a flag
   * @param  {string} lang
   */
  function handleFlagSelected(lang) {
    if (lang !== currentLangId) {
      actions.setLanguage(lang);
    }
    actions.setIndexMenuItemActive(getCurrentPageProps().indexMenuItem);
    actions.hideChooseLangDialog();
  }

  const dialogProps = {
    ...DEFAULT_DIALOG_PROPS,
    open: isOpen,
    onClose: actions.hideChooseLangDialog, // important, to update `open` value in all cases
  };

  return (
    <Dialog {...dialogProps}>
      <DialogTitle className="title-font modal-title">{labels.common.chooseLang}</DialogTitle>
      <div className="generic-modal-content">
        <div id="cl-langs">
          <ChooseLangInline
            labels={labels}
            actions={actions}
            onFlagClick={handleFlagSelected}
            displayType="label"
          />
        </div>

        <div className="generic-btn-container">
          <div
            className="generic-btn-cancel"
            onPointerDown={(e) => {
              if (e) {
                e.stopPropagation();
                e.preventDefault();
              }
              setPrevIndexMenuItem();
              actions.hideChooseLangDialog();
            }}
          >
            {labels.common.cancel}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

ChooseLangDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => state[COMPONENT_KEY];
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(ChooseLangDialog);
