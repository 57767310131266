import React, { useEffect, useRef } from 'react';

export default ({ layoutChild: ChildSubComponent, show, controller, style, ...rest }) => {
  const wrapper = useRef(null);
  const NOT_CLOSING_CLASS = ['description-text-content'];
  const clickHandler = (e) => {
    if (show && !e.target.classList.contains(NOT_CLOSING_CLASS)) {
      controller();
    }
  };
  return show ? (
    <div style={style} className="modal-pop-details" ref={wrapper} onPointerDown={clickHandler}>
      <span
        className="fal fa-times fa-3x btn-close-modal"
        onPointerDown={(e) => {
          if (e) {
            e.stopPropagation();
            e.preventDefault();
          }
          return controller();
        }}
      />
      <ChildSubComponent {...rest} />
    </div>
  ) : (
    <></>
  );
};
