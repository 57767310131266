import { TOGGLE_SCREEN_SAVER } from 'src/store/actionTypes';
import {reloadTimeout} from 'src/core/exit/exitService'
import config from 'data/config/config';

export default ({ dispatch, getState }) => (next) => (action) => {
  const result = next(action);
  switch (action.type) {
    case TOGGLE_SCREEN_SAVER:
      if (!action.showScreenSaver) {
        // clear timeout if set on exitService
        reloadTimeout && window.clearTimeout(reloadTimeout);
      }
    default:
  }
  return result;
};
