import { ITINERARY_PAGE_KEY } from 'src/pages/pagesKeys';
import ItineraryPageContent from 'src/pages/itinerary/itineraryPage'
import GenericItemPage, { DOM_ID } from 'src/pages/generic-item-page/GenericItemPage';

import reducer from './itineraryPageReducer';

export default {
  key: ITINERARY_PAGE_KEY,
  path: '/itinerary',
  elId: DOM_ID,
  className: 'details-page',
  component: GenericItemPage,
  childComponent: ItineraryPageContent,
  relatedDataToFetch: ['places'],
  getReduxReducer: (state, action) => reducer(state[ITINERARY_PAGE_KEY], action),
};