import {
  LANG_CHANGED,
  DATA_ASSETS_UPDATED,
  DATA_ASSETS_UPDATING,
  HIDE_FULL_LOADER,
  MOBIGEO_LOADED,
  MOBIGEO_RELOAD
} from 'src/store/actionTypes';

import { showFullLoader, hideFullLoader, showDisclaimer } from 'src/store/actions';

export default ({ dispatch, getState }) => (next) => (action) => {
  const result = next(action);
  switch (action.type) {
    case LANG_CHANGED:
    // A bit redundant with DATA_ASSETS_UPDATING (as lang change implies data reload).
    // but on old devices there is a delay between these two actions, and loader should be
    // displayed as soon as possible
    // FALL-THROUGH
    // eslint-disable-line no-fallthrough
    case MOBIGEO_RELOAD:
    case DATA_ASSETS_UPDATING:
      dispatch(showFullLoader());
      break;

    case MOBIGEO_LOADED:
      dispatch(hideFullLoader());
      break;

    case HIDE_FULL_LOADER:
      // dispatch(showDisclaimer());
      break;

    default:
  }
  return result;
};
