import React from 'react';
import PropTypes from 'prop-types';

import './LangButton.scss';

function LabelButton({ className, lang, labels, onClick }) {
  if (!lang) {
    return null;
  }

  const cssClasses = ['label-select', `flag-${lang}`];

  function _onClick(e) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (typeof onClick === 'function') {
      onClick(lang);
    }
  }

  return (
    <span
      className={`lang-options ${className || ''}`}
      title={labels ? labels.lang[lang] : ''}
      onPointerDown={_onClick}
    >
      {labels.lang[lang]}
    </span>
  );
}

LabelButton.propTypes = {
  className: PropTypes.string,
  lang: PropTypes.string.isRequired,
  labels: PropTypes.object,
  onClick: PropTypes.func,
};

export default LabelButton;
