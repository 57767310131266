import { MONUMENT_PAGE_KEY } from 'src/pages/pagesKeys';

import GenericItemPage, { DOM_ID } from 'src/pages/generic-item-page/GenericItemPage';
import MonumentContent from './MonumentContent';
import ItineraryPageContent from 'src/pages/itinerary/itineraryPage'

export default {
  key: MONUMENT_PAGE_KEY,
  path: '/monument',
  elId: DOM_ID,
  className: 'monument-page',
  component: GenericItemPage,
  childComponent: ItineraryPageContent,
  childSubComponent: MonumentContent,
  relatedDataToFetch: ['places'],
};
