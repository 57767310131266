import { MOBIGEO_LOADED, MOBIGEO_RELOAD, LANG_CHANGED, TOGGLE_CUSTOM_KEYBOARD } from 'src/store/actionTypes';

import { updateObject } from 'src/store/reducers/commons';
import { toggleCustomKeyboard } from '../../store/reducers/commons';

const Immutable = require('immutable');

const getDefaultState = () => ({
  mapLoaded: false,
});

/**
 * Map initialization:
 *   - App is ready for map loading when data is loaded
 *
 * Map restart if:
 *   - data has been updated OR at least an asset whose path contains 'files/maps/' has been updated
 *   - map is not currently displayed
 *
 * @param {boolean} value
 */
const _mapLoaded = (state, action, value) => updateObject(state, { mapLoaded: value});

export default (state = getDefaultState(), action) => {
  switch (action.type) {
    case MOBIGEO_LOADED:
      return _mapLoaded(state, action, true);
    case MOBIGEO_RELOAD:
    case LANG_CHANGED:
      return _mapLoaded(state, action, false);
    case TOGGLE_CUSTOM_KEYBOARD:
      return toggleCustomKeyboard(state, action)
    default:
      return state;
  }
};
