import { WINDOW_RESIZED, LANG_CHANGED, TOGGLE_SCREEN_SAVER } from 'src/store/actionTypes';

import { windowResized, langChanged, updateObject } from 'src/store/reducers/commons';
import { get as getLabels, getCurrent as getLang } from 'src/core/Lang';

const getDefaultState = () => ({
  lang: getLang(),
  labels: getLabels(),
  screenSaverVisible: false,
});

export const toggleScreenSaver = (state, action) => {
  return updateObject(state, {
    screenSaverVisible: action.showScreenSaver ? action.showScreenSaver : !state.screenSaverVisible,
  });
};

export default (state = getDefaultState(), action) => {
  switch (action.type) {
    case TOGGLE_SCREEN_SAVER:
      return toggleScreenSaver(state, action);
    case WINDOW_RESIZED:
      return windowResized(state, action);
    case LANG_CHANGED:
      return langChanged(state, action);

    default:
      return state;
  }
};
