import { RESTAURANT_PAGE_KEY } from 'src/pages/pagesKeys';

import GenericItemPage, { DOM_ID } from 'src/pages/generic-item-page/GenericItemPage';
import ItineraryContent from 'src/pages/itinerary/itineraryPage'
import RestaurantContent from './RestaurantContent';

export default {
  key: RESTAURANT_PAGE_KEY,
  path: '/restaurant',
  elId: DOM_ID,
  className: 'restaurant-page',
  component: GenericItemPage,
  childComponent: ItineraryContent,
  childSubComponent: RestaurantContent,
  relatedDataToFetch: ['places'],
};
