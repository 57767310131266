import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import config from 'data/config/config';

import { getBindedActions } from 'src/store/bindedActions';
// import Lottie from 'lottie-react-web'
// import animation from '../../../../data/native_resources/sources/animation.json' // no animation yet

import './ScreenSaver.scss';

export const COMPONENT_KEY = 'ScreenSaver';
export const CONTAINER_DOM_ID = 'screen-saver-container';
const TIMER = { movement: 5000, randomLang: 5000 };

var mousetimeout = undefined;

const timerScreenSaver = config.timerScreenSaver || 120;
const eventsTypesList = [
  'click',
  'touchmove',
  'touchstart',
  'touchend',
  'pointerdown',
  'pointerup',
  'pointermove',
];
class ScreenSaver extends Component {
  constructor(props) {
    super(props);

    this.state = {
      posX: '100px',
      posY: '100px',
      lang: this.props.labels.id,
    };
  }
  intervalPosition = null;
  intervalLang = null;
  indexLang = 0;

  componentDidMount() {
    this.intervalPosition = window.setInterval(() => {
      this.setState({
        posX: this.randomPosition({ min: 50, max: window.innerWidth - 845 }),
        posY: this.randomPosition({ min: 100, max: window.innerHeight - 380 }),
      });
    }, TIMER.movement);
    this.intervalLang = setInterval(() => {
      this.setState({
        lang: this.changeLangScreen(this.indexLang),
      });
      this.indexLang =
        this.indexLang === config.SUPPORTED_LANGS.length - 1 ? 0 : this.indexLang + 1;
    }, TIMER.randomLang);

    eventsTypesList.forEach(
      function(e) {
        window.addEventListener(e, this.initAndResetScreenSaver.bind(this));
      }.bind(this)
    );
    this.initAndResetScreenSaver();
  }
  componentWillUnmount() {
    window.clearInterval(this.intervalPosition);
    window.clearInterval(this.intervalLang);

    eventsTypesList.forEach(
      function(e) {
        window.removeEventListener(e, this.initAndResetScreenSaver);
      }.bind(this)
    );
  }
  randomPosition(margeValue) {
    const { min, max } = margeValue;
    let value = `${Math.floor(Math.random() * (max - min)) + min}px`;
    return value;
  }

  randomSupportedLang() {
    return config.SUPPORTED_LANGS[Math.floor(Math.random() * config.SUPPORTED_LANGS.length)];
  }

  changeLangScreen(i) {
    return config.SUPPORTED_LANGS[i];
  }
  initAndResetScreenSaver() {
    clearTimeout(mousetimeout);
    mousetimeout = setTimeout(function() {
      getBindedActions().exitApp();
    }, 1000 * timerScreenSaver);
  }
  stop_screensaver() {
    getBindedActions().showChooseLangFullScreen();
    getBindedActions().toggleScreenSaver(false);
  }

  screenWithMovingTitle = () => (
    <div className="home_container" style={{ left: this.state.posX, top: this.state.posY }}>
      <img src={'./lib/img/hand_and_pin_printemps.svg'} className="home_img" />
      <div className="home_text">
        <h1 className="home_title">{this.props.labels['screenSaver_' + this.state.lang].title}</h1>
        <h1 className="home_instruction">
          {this.props.labels['screenSaver_' + this.state.lang].subTitle}
        </h1>
      </div>
    </div>
  );
  
  screenStatic = () =>  (
    <div className="home_container">
      <div className="home_text">
        <h1 className="home_title">{this.props.labels['screenSaver_' + this.state.lang].title}</h1>
        <div className="home_logo">
          <img src={'./lib/img/Visuel_EcranAttente.svg'} className="home_img" />
           {/* <Lottie
              options={{
                animationData: animation
              }}
            /> */}
        </div>
        <h1 className="home_instruction">
          {this.props.labels['screenSaver_' + this.state.lang].subTitle}
        </h1>
      </div>
    </div>
  )

  render() {
    return (
      this.props.screenSaverVisible && (
        <div
          onPointerDown={(e) => {
            if (e) {
              e.stopPropagation();
              e.preventDefault();
            }
            this.stop_screensaver();
          }}
          className={'screensaver_show'}
          style={{ backgroundColor: 'white' }}
          id="screensaver"
        >
          {this.screenStatic()}
          {/* <h1>{this.props.labels && this.props.labels.screenSaver.title}</h1> */}
        </div>
      )
    );
  }
}

ScreenSaver.propTypes = {
  screenSaverVisible: PropTypes.bool,
  labels: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => state[COMPONENT_KEY];
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ScreenSaver);
