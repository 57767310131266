import React from 'react';
import PropTypes from 'prop-types';

import './CTAButton.scss';

/**
 * Call-to-action button
 * @param  {object} props
 */
const CTAButton = ({ label, action, isEnabled, children, className, ...remainingProps }) => (
  <div className="cta-btn-container" {...remainingProps}>
    <div
      className={`${className || ''} cta-btn ${isEnabled === false ? 'cta-btn-disabled' : ''}`}
      onPointerDown={isEnabled === false ? null : action}
    >
      {label}
      {children}
    </div>
  </div>
);

CTAButton.propTypes = {
  label: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  isEnabled: PropTypes.bool,
};

export default CTAButton;
