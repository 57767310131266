import config from 'data/config/config';

import { getDatatypeFromPage } from 'src/pages/dataToPageMapping';
import { applyCurrentUrl } from 'src/core/navigation/Router';
import { serialize } from 'src/core/util/JsTools';
import { get } from 'src/core/query/Query';
import { getCurrent as getCurrentLang } from 'src/core/Lang';
import { isAndroid } from 'src/core/util/browser';

const LOG_PREF = '[History] ';

export const HISTORY_ACTIONS = {
  PUSH: 'PUSH',
  REPLACE: 'REPLACE',
  NONE: 'NONE',
};

export const getLocation = () => window.history.location || window.location;

export const getCurrentState = () => window.history.state;

/**
 * Navigate back (= pop previous history entry)
 */
export function back() {
  window.history.back();
}

/**
 * Handle when history is manipulated (navigating forward or backward)
 */
window.onpopstate = (e) => {
  // We define `state` when routing, so no state = no more routing history

  // On Android, if back button is pressed and history stack is empty then exit the app
  if (!e.state && isAndroid() && navigator.app && typeof navigator.app.exitApp === 'function') {
    navigator.app.exitApp();
    return;
  }

  applyCurrentUrl(HISTORY_ACTIONS.NONE);
};

export function encodeProps(pageProps, pageKey) {
  let encoded;

  if (!pageProps || typeof pageProps !== 'object' || Object.keys(pageProps).length === 0) {
    encoded = '';
  } else {
    // Clone to avoid modifying original object
    const _props = JSON.parse(JSON.stringify(pageProps));

    // id -> originalId (allows url sharing)
    if (typeof _props.id !== 'undefined' && _props.id !== null) {
      const dataType = pageProps.dataType || getDatatypeFromPage(pageKey);
      if (dataType) {
        // Get originalId value for this item
        const item = get(_props.id, dataType);
        if (item && item.original_id) {
          _props.originalId = item.original_id;
          delete _props.id;
        }
      }
    }

    // Remove twocolumns from url
    if (typeof _props.twocolumns !== 'undefined') {
      delete _props.twocolumns;
    }

    // Add `lang`
    _props.lang = getCurrentLang();
    if (_props && _props.inputs && _props.inputs[0].title) {
      _props.inputs[0].title = encodeURIComponent(_props.inputs[0].title);
    }

    // When a link is shared between 2 persons, it is possible that
    // they use different profiles, so keep it local, don't expose it.
    delete _props.profile;

    encoded = serialize(_props);
  }
  return encoded;
}

export const getNavigationIndex = () =>
  getCurrentState() ? getCurrentState().navigationIndex : null;

/**
 * Add an entry to browser history
 * @param  {object} page
 * @param  {object} pageProps
 */
export function push(page, pageProps) {
  // iOS 11.3+wkwebview: major regression on HTML5 History API support
  // @see https://bugs.webkit.org/show_bug.cgi?id=183028
  if (typeof window.history.pushState !== 'function') {
    return;
  }

  // Push state only if 'pageKey' or 'page arguments' has changed (good for page refreshs)
  if (
    (!getCurrentState() ||
      page.key !== getCurrentState().pageKey ||
      JSON.stringify(pageProps) !== JSON.stringify(getCurrentState().pageProps)) &&
    page.key !== 'Map'
  ) {
    const newState = getNewHistoryState(page, pageProps);
    console.log(`${LOG_PREF}pushing to history`, page.path, pageProps, newState);
    window.history.pushState(newState, `${page.key} page`, newState.queryString);
  }
}

/**
 * Replace current entry in browser history
 * @param  {object} page
 * @param  {object} pageProps
 */
export function replaceState(page, pageProps) {
  // iOS 11.3+wkwebview: major regression on HTML5 History API support
  // @see https://bugs.webkit.org/show_bug.cgi?id=183028
  if (typeof window.history.replaceState !== 'function') {
    return;
  }

  const newState = getNewHistoryState(page, pageProps);
  newState.navigationIndex = 0; // reset
  console.log(`${LOG_PREF}replacing history state`, page.path, pageProps, newState);
  window.history.replaceState(newState, `${page.key} page`, newState.queryString);
}

function getNewHistoryState(page, pageProps) {
  // Encode pageProps
  const encodedOptions = encodeProps(pageProps, page.key);

  const queryString = config.ROUTE_SEPARATOR + page.path + encodedOptions;

  // Determine navigation index
  let navigationIndex = getNavigationIndex();
  if (typeof navigationIndex === 'number') {
    navigationIndex += 1;
  } else {
    navigationIndex = 0;
  }

  return {
    pageKey: page.key,
    pageProps,
    navigationIndex,
    queryString,
  };
}
