import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AdBanner from 'src/components/ad-banner/AdBanner';
import { getLeastSeenAdFromBundle } from 'src/core/config-json/ConfigJsonManager';

import * as actions from 'src/store/actions';

// const LOG_PREF = '[AdSwap] ';

export const STORE_KEY = 'AdSwap';

class AdSwap extends React.Component {
  constructor(props) {
    super(props);

    this.state = { time: null };
  }

  getBundle() {
    // If any property is missing, skip component rendering
    if (
      this.props.adBundleAttributionKey &&
      this.props.adConfig &&
      this.props.adConfig.ads &&
      this.props.adConfig.swap_delay &&
      this.props.adConfig.bundles &&
      this.props.adConfig.bundleAttributions
    ) {
      let bundleName = this.props.adConfig.bundleAttributions[this.props.adBundleAttributionKey];
      if (!bundleName) {
        console.log('No ad bundle for ' + this.props.adBundleAttributionKey);
        return null;
      }
      return this.props.adConfig.bundles[bundleName];
    }
  }

  triggerRender = () => {
    this.setState({ time: new Date().getTime() });
  };

  shouldComponentUpdate(nextProps, nextState) {
    let isPageVisibleChanged = this.props.isPageVisible !== nextProps.isPageVisible,
      isAppVisibleChanged = this.props.isAppVisible !== nextProps.isAppVisible,
      adConfigHasChanged = this.props.adConfig !== nextProps.adConfig,
      timeHasChanged = this.state.time !== nextState.time,
      pageAndAppAreVisible = nextProps.isPageVisible && nextProps.isAppVisible;

    return !!(
      pageAndAppAreVisible &&
      (timeHasChanged || isPageVisibleChanged || isAppVisibleChanged || adConfigHasChanged)
    );
  }

  logToAnalytics = ((ad) => {
    this.props.actions.adSwap(this.props.adBundleAttributionKey, ad);
  }).bind(this);

  render() {
    return null;
  }
}

AdSwap.propTypes = {
  adBundleAttributionKey: PropTypes.string, // can be null if no ad is specified for a list
  isPageVisible: PropTypes.bool.isRequired,
  isAppVisible: PropTypes.bool.isRequired,
  // from store:
  adConfig: PropTypes.object,
  actions: PropTypes.object,
  labels: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => state[STORE_KEY];
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(AdSwap);
