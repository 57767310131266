import config from 'data/config/config';
import { get as getSearchConfig } from 'data/config/searchConfig';
import getToolbarConfig from 'data/config/toolbarConfig';
import { isLogoFullWidth } from 'data/config/ficheConfig';

import { DATA_TYPE_EVENTS, DATA_TYPE_PARTICIPANTS } from 'data/config/dataConfig';

import { AUTOSHOW_POI_ON_MAP } from 'data/config/pagesTwoColumnsConfig';

import {
  ACTIVATE_CONTRIBUTIONS_REFRESH,
  ALL_FAVORITES_DELETED,
  APPOINTMENT_REQUEST_SEND_RESULT,
  APPOINTMENT_REQUEST_SENT,
  CLEAR_SEARCH_RESULTS,
  CONFIG_JSON_LOADED,
  CONTRIBUTIONS_FEED_LOADED,
  DATA_ASSETS_UPDATED,
  FETCH_FAVORITES,
  HAS_NAVIGATED,
  ITEM_BEING_FETCHED,
  ITEM_FETCHED,
  KEYBOARD_TOGGLED,
  LANG_CHANGED,
  NAVIGATE,
  NOTE_DELETED,
  NOTE_SAVED,
  POLL_CONFIG_LOADED,
  PROFILE_CHANGED,
  SEARCH_PERFORMED,
  SET_SEARCH_FIELD_VISIBLE,
  TOGGLE_FAVORITE,
  TOGGLE_LOCATION_STATUS,
  TOGGLE_MENU,
  UPDATE_PAGE_STATE,
  USER_DATA_UPDATED,
  SET_INDEX_MENU_ITEM_ACTIVE,
  TOGGLE_PMR_STATUS,
} from 'src/store/actionTypes';

import {
  clearSearchResults,
  configJsonLoaded,
  fetchFavorites,
  getPageCommonDefaultState,
  handleSetSearchFieldVisible,
  itemFetched,
  langChanged,
  noteDeleted,
  noteSaved,
  pollConfigLoaded,
  profileChanged,
  searchPerformed,
  setIsFavoriteFalse,
  toggleLocationStatus,
  toggleMenu,
  togglePageAfterNavigation,
  updateKeyboardState,
  updateObject,
  setIndexMenuItemActive
} from 'src/store/reducers/commons';

import Pages from 'src/pages/Pages';
import { getDatatypeFromPage } from 'src/pages/dataToPageMapping';

import { getRequestStatus } from 'src/core/appointment-request/AppointmentRequestService';
import { persistence as contributionsPersistence } from 'src/core/contributions-feed/ContributionsFeedService';
import { isFavorite } from 'src/core/favorites/Favorites';
import GenericItemPage from './GenericItemPage';
import { DATA_TYPE_LIVESTREAM } from '../../../data/config/dataConfig';

const getDefaultState = () => ({
  ...getPageCommonDefaultState(),
  appointmentRequestStatus: {
    isEnabled: config.APPOINTMENT_REQUEST.FEATURE_ENABLED === true,
  },
});

/**
 *
 * @param  {object} state
 * @param  {object} action
 * @param  {string} contextualDataType
 * @return {object}
 */
const _toggleFavorite = (state, action, contextualDataType) => {
  let update = {};
  if (
    action.favListUpdated &&
    action.dataType === contextualDataType &&
    typeof state.id !== 'undefined' &&
    state.id !== null &&
    state.id == action.id
  ) {
    update = {
      isFavorite: isFavorite(state.id, contextualDataType),
    };
  }

  return updateObject(state, {
    favorites: action.favorites,
    ...update,
  });
};

const _togglePMRStatus = (state, action) => {
  return updateObject(state, {
    isPMREnabled: action.value
  })
}

function _itemFetched(state, action) {
  let dataType = currentDataType === DATA_TYPE_LIVESTREAM ? DATA_TYPE_EVENTS : currentDataType; 
  let newState = itemFetched(state, action, dataType);

  if (newState !== state) {
    // State updated

    if (newState.appointmentRequestStatus.isEnabled) {
      // Check if an appointment request has already been sent
      const originalId = action.item.original_id;
      const appointmentRequestStatus = getRequestStatus(originalId, currentDataType);

      return __updateAppointmentRequestStatus(newState, appointmentRequestStatus);
    }
  }

  if (action.dataType === DATA_TYPE_EVENTS) {
    newState = _getEventContrib(newState);
  }
  if (action.dataType === DATA_TYPE_PARTICIPANTS) {
    newState.contactDate = action.contactDate;
    newState.contactDateLabel = action.contactDateLabel;
    newState.isSameUSer = action.isSameUSer;
  }

  return newState;
}

function _itemBeingFetched(state, action) {
  if (action.id === state.id && action.dataType === state.dataType) {
    return updateObject(state, {
      shouldFetch: false,
      isPending: true,
      item: null,
    });
  }
  return state;
}

/**
 * Request to the backend has been sent
 */
function _appointmentRequestSent(state, action) {
  if (action.dataType === currentDataType && action.dataId === state.id) {
    return __updateAppointmentRequestStatus(state, null, true);
  }
  return state;
}

/**
 * Request to the backend is over
 */
function _appointmentRequestSendResult(state, action) {
  if (action.dataType === currentDataType && action.dataId === state.id) {
    return __updateAppointmentRequestStatus(state, action.status);
  }
  return state;
}

/**
 * Common function to update `appointmentRequestStatus`
 * @param  {object}  state
 * @param  {object}  status
 * @param  {boolean} ongoing
 */
function __updateAppointmentRequestStatus(state, status, ongoing = false) {
  const newState = { ...state };
  newState.appointmentRequestStatus = { ...state.appointmentRequestStatus };

  if (status && status.date) {
    // Request success
    newState.appointmentRequestStatus.date = status.date;
    newState.appointmentRequestStatus.userEmail = status.userEmail;
    newState.appointmentRequestStatus.userPhone = status.userPhone;
  } else {
    // Ongoing or request failed
    newState.appointmentRequestStatus.date = null;
    newState.appointmentRequestStatus.userEmail = null;
    newState.appointmentRequestStatus.userPhone = null;
  }
  newState.appointmentRequestStatus.ongoing = ongoing;

  return newState;
}

let currentPageKey;
let currentDataType;

function _updatePageState(state, action) {
  if (isAGenericItemPage(action.pageKey)) {
    currentPageKey = action.pageKey;
    currentDataType = getDatatypeFromPage(currentPageKey);

    const toolbarConfig = getToolbarConfig(state.profile, currentPageKey);

    return updateObject(state, {
      childComponent: Pages[currentPageKey].childComponent,
      childSubComponent: Pages[currentPageKey].childSubComponent,
      dataType: currentDataType,
      shouldFetch: true,
      isPending: false,
      itemNotFound: false,
      autoShowPoi: AUTOSHOW_POI_ON_MAP[currentPageKey] === true,
      hasContextualSearch: !!getSearchConfig(state.profile, currentPageKey)[currentDataType],
      isLogoFullWidth: isLogoFullWidth(currentPageKey),
      searchResults: null,
      searchFieldVisible: null,
      ...action.props,
      ...toolbarConfig,
    });
  }
  return state;
}

function _navigate(state, action) {
  if (isAGenericItemPage(action.pageKey)) {
    if (action.pageKey !== currentPageKey || (action.options && action.options.id !== state.id)) {
      return updateObject(state, {
        item: null,
        shouldFetch: false,
        isPending: true,
      });
    }
  }
  return state;
}

const isAGenericItemPage = (pageKey) => pageKey && Pages[pageKey].component === GenericItemPage;

const _getEventContrib = (state) => {
  const contributions = contributionsPersistence.get();

  if (state.item && state.item.lump && state.item.lump.rt_code) {
    if (contributions && contributions.value && contributions.value.sc_code) {
      const { rt_code } = state.item.lump;
      const eventContrib = contributions.value.sc_code[rt_code];
      const contribDate = contributions.value.current_time;

      if (eventContrib) {
        return updateObject(state, {
          contributions: {
            date: contribDate,
            events: eventContrib,
          },
        });
      }
    }

    return updateObject(state, { contributions: { empty: true } });
  }

  return updateObject(state, { contributions: null });
};

function _userDataUpdated(state, action) {
  if (!state.item) {
    return state;
  }
  // This works, but fetching the whole item triggers a visible 'loading' sequence
  // should fetch only if user data update and ??? the genericItemPage with autoShowPoi ???
  return updateObject(state, { shouldFetch: state.autoShowPoi });

  /*
    let updatedItem = get(state.id, state.dataType, Pages[currentPageKey].relatedDataToFetch);

    // Alternative solution:
    // Directly update the item
    //  - more code to maintain
    //  + better user experience
    return updateObject(state, {
        item: updatedItem,
    });
    */
}

export default (state = getDefaultState(), action) => {
  switch (action.type) {
    case ACTIVATE_CONTRIBUTIONS_REFRESH:
      return updateObject(state, { activateRefresh: action.activate });

    case ALL_FAVORITES_DELETED:
      return setIsFavoriteFalse(state);

    case APPOINTMENT_REQUEST_SEND_RESULT:
      return _appointmentRequestSendResult(state, action);

    case APPOINTMENT_REQUEST_SENT:
      return _appointmentRequestSent(state, action);

    case CLEAR_SEARCH_RESULTS:
      return clearSearchResults(state, action, currentPageKey);

    case CONFIG_JSON_LOADED:
      return configJsonLoaded(state);

    case CONTRIBUTIONS_FEED_LOADED:
      return _getEventContrib(state, action.payload);

    case DATA_ASSETS_UPDATED:
      return updateObject(state, { shouldFetch: true });

    case FETCH_FAVORITES:
      return fetchFavorites(state, action);

    case HAS_NAVIGATED:
      return togglePageAfterNavigation(state, currentPageKey);

    case ITEM_BEING_FETCHED:
      return _itemBeingFetched(state, action);

    case ITEM_FETCHED:
      return _itemFetched(state, action);

    case KEYBOARD_TOGGLED:
      return updateKeyboardState(state, action);

    case LANG_CHANGED:
      return langChanged(state, action);

    case NAVIGATE:
      return _navigate(state, action);

    case NOTE_DELETED:
      return noteDeleted(state, action);

    case NOTE_SAVED:
      return noteSaved(state, action);

    case POLL_CONFIG_LOADED:
      return pollConfigLoaded(state, action);

    case PROFILE_CHANGED:
      return profileChanged(state, action, currentPageKey);

    case SEARCH_PERFORMED:
      return searchPerformed(state, action, currentPageKey);

    case SET_SEARCH_FIELD_VISIBLE:
      return handleSetSearchFieldVisible(state, action, currentPageKey);

    case TOGGLE_FAVORITE:
      return _toggleFavorite(state, action, currentDataType);

    case TOGGLE_LOCATION_STATUS:
      return toggleLocationStatus(state, action);

    case TOGGLE_MENU:
      return toggleMenu(state, action, currentPageKey);

    case SET_INDEX_MENU_ITEM_ACTIVE:
      return setIndexMenuItemActive(state, action);

    case UPDATE_PAGE_STATE:
      return _updatePageState(state, action);

    case USER_DATA_UPDATED:
      return _userDataUpdated(state, action);
    
    case TOGGLE_PMR_STATUS:
      return _togglePMRStatus(state, action)

    default:
      return state;
  }
};
