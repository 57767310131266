import { LIST_PAGE_KEY } from 'src/pages/pagesKeys';
import { getBindedActions } from 'src/store/bindedActions';
import { HISTORY_ACTIONS } from 'src/core/navigation/History';
import { getLastUpdateTime } from 'src/core/data-and-assets/DataAssetsUtil'
import config from 'data/config/config';
import { DATA_TYPE_BRAND_CATEGORIES } from '../../../data/config/dataConfig';
import { getCurrent as getCurrentLang } from 'src/core/Lang';

export let reloadTimeout = null;
const randomAdditionalTime = Math.floor(Math.random() * (config.randomTimeInterval.max - config.randomTimeInterval.min + 1)) + config.randomTimeInterval.min;
const reloadTimer = ((config.reloadTimer || 3600) + randomAdditionalTime) * 1000;

export function onExitApp() {
  getBindedActions().setIndexMenuItemActive(0);
  getBindedActions().toggleScreenSaver(true);
  getBindedActions().toggleCustomKeyboard(false);
  getBindedActions().hideChooseLangFullScreen();
  getBindedActions().navigate(
    LIST_PAGE_KEY,
    {
      locateAll: false,
      inputs: [{ dataType: DATA_TYPE_BRAND_CATEGORIES }],
      indexMenuItem: 0,
    },
    HISTORY_ACTIONS.REPLACE
  );
  reloadManager();
  getBindedActions().resetListDisplayState();
  // if(getCurrentLang() !== config.DEFAULT_LANG){
  //   getBindedActions().setLanguage(config.DEFAULT_LANG, true);
  // }
  getBindedActions().resetMap();
}

const reloadManager = () => {
  const delta = Date.now() - getLastUpdateTime();
  const remainingTime = reloadTimer - delta;
  if( delta > reloadTimer) {
    location.reload();
  } else {
    reloadTimeout = window.setTimeout(() => location.reload(), remainingTime);
  }
};