let currentPageKey;

export const getCurrentPageKey = () => currentPageKey;

export function setCurrentPageKey(value) {
  currentPageKey = value;
}

let currentPageProps;

export const getCurrentPageProps = () => currentPageProps;

export function setCurrentPageProps(value) {
  currentPageProps = value;
}

let startPosition;
let endPosition;

const correctValuePOI = (value) => {
  if(!value.floor || !value.zone || !value.x || !value.y || !value.title){
    console.error('failed a value is null or undefined ', value);
    return null;
  }
  return {
    floor: String(value.floor),
    zone: parseInt(value.zone),
    x: parseInt(value.x, 10),
    y: parseInt(value.y, 10),
    title: String(value.title)
  }
}

let pmr;
export const getStartPosition = () => startPosition;
export const setStartPosition = (value) => {
  try {
  let valueConvert = typeof value === 'string' ? JSON.parse(value) : value;
  startPosition = correctValuePOI(valueConvert);
} catch (error) {
  console.error('error on set startPosition', error);
}
};

export const getEndPosition = () => endPosition;
export const setEndPosition = (value) => {
  try {
    const valueParsed = typeof value === 'object' ? value : JSON.parse(value);
    endPosition = valueParsed;
    
  } catch (error) {
    console.error('error on set end position', error)
  }
};

export const getPmr = () => pmr;
export const setPmr = (value) => {pmr = value};
