import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash-custom';

import { iosHackScrollTo0 } from 'src/core/util/browser';

import './SearchField.scss';

const LOG_PREF = '[SearchField] ';

const CLEAR_ICON_INACTIVE_STYLE = { opacity: 0 };
const CLEAR_ICON_ACTIVE_STYLE = { opacity: 1 };

class SearchField extends React.PureComponent {
  constructor(props) {
    super(props);

    this.inputEl = null;

    this.state = {
      value: this.props.value || '',
      clearIconStyle: CLEAR_ICON_INACTIVE_STYLE,
    };

    this.setInputElement = this.setInputElement.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  setInputElement(el) {
    this.inputEl = el;
  }

  onInputChange(e) {
    this.setValue(e.target.value);
  }

  /**
   * Handle search value change
   * @param {string} newValue
   */
  setValue(newValue) {
    this.setState({ value: newValue });

    if (!newValue || newValue.length === 0) {
      // CASE OF EMPTY INPUT
      this.setState({ clearIconStyle: CLEAR_ICON_INACTIVE_STYLE });

      // Stop debounced search
      this.debouncedPerform.cancel();

      // Remove search results
      this.props.clearResults();
    } else {
      // CASE OF NON-EMPTY INPUT
      this.setState({ clearIconStyle: CLEAR_ICON_ACTIVE_STYLE });

      // Trigger search
      if (newValue.length > this.props.minChars - 1) {
        this.debouncedPerform(newValue);
      }
    }
  }

  /**
   * Spare CPU - don't send a search action for each character typed
   */
  debouncedPerform = debounce((str) => {
    this.props.perform(str);
  }, 500);

  clearBtnClickHandler = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.clear();
    this.openKeyboard(); // keep keyboard displayed
  };

  blur = () => {
    if (this.inputEl) {
      this.inputEl.blur();
    }
  };

  /**
   * Clear typed string
   */
  clear = () => {
    if (this.inputEl) {
      this.inputEl.value = '';
      this.setValue('');
      this.props.setKeyboardInput('');
    }
  };

  openKeyboard = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (this.inputEl) {
      this.props.toggleCustomKeyboard(true);
      this.inputEl.focus();
    }
  };

  onBlur = (e) => {
    iosHackScrollTo0();
    if (typeof this.props.onBlur === 'function') {
      this.props.onBlur(e);
    }
  };

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.props.searchResults && !nextProps.searchResults) {
      this.clear();
    }
    if (nextProps.keyboardInput !== this.props.keyboardInput) {
      this.setValue(nextProps.keyboardInput);
    }
  }

  /**
   * special cancel button handler (e.g to hide search field)
   */
  cancel = () => {
    if (typeof this.props.onCancel !== 'function') {
      console.error(
        'Implementation error: SearchField has a cancel button ' +
          '(`hasCancelButton` prop is truthy) but is missing `onCancel` prop (function)'
      );
      return;
    }
    if (typeof this.props.toggleCustomKeyboard === 'function') {
      this.props.toggleCustomKeyboard(false);
    }
    this.props.onCancel();
    this.clear();
  };

  render() {
    console.log(LOG_PREF + 'render');
    return (
      <div
        className={
          'search-field-container ' + (this.props.isContextual ? 'search-field-contextual' : '')
        }
        onPointerDown={this.openKeyboard}
      >
        <div className="search-field-input-container">
          {this.props.hasSearchButton && (
            <span className="search-field-search far fa-search" />
          )}
          <input
            ref={this.setInputElement}
            value={this.state.value}
            onChange={this.onInputChange}
            onBlur={this.onBlur}
            placeholder={this.props.placeholder}
          />

          {this.props.hasClearButton && (
            <span
              className="search-field-clear fal fa-times"
              style={this.state.clearIconStyle}
              onPointerDown={this.clearBtnClickHandler}
            />
          )}
        </div>

        {this.props.hasCancelButton && (
          <div className="search-field-cancel-container">
            <span className="search-field-cancel fa fa-times" onClick={this.cancel} />
          </div>
        )}
      </div>
    );
  }
}

SearchField.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  perform: PropTypes.func.isRequired,
  clearResults: PropTypes.func.isRequired,
  minChars: PropTypes.number.isRequired,
  onBlur: PropTypes.func,
  isContextual: PropTypes.bool,
  hasClearButton: PropTypes.bool,
  hasSearchButton: PropTypes.bool,
  hasCancelButton: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default SearchField;
