import config from 'data/config/config';
import { getBindedActions } from 'src/store/bindedActions';
import { HISTORY_ACTIONS } from '../navigation/History';

const LOG_PREF = '[ConfigItemFactory] ';

export const TYPES = {
  // Execute a random function
  ACTION: 'ACTION',

  // Navigate to a page (navigation action)
  ROUTE: 'ROUTE',

  TOGGLE_LOCATION: 'TOGGLE_LOCATION',
  TOGGLE_PMR: 'TOGGLE_PMR',
};

function _itemFactory(style, label, type, func, entryOptions) {
  // Check arguments
  if (typeof label !== 'string') {
    console.error(`${LOG_PREF}Unexpected label of type ${typeof label}`);
  }
  if (typeof TYPES[type] === 'undefined') {
    console.error(`${LOG_PREF}Unexpected item type ${type}, supported values are:`, TYPES);
  }
  if (typeof func !== 'function') {
    console.error(`${LOG_PREF}Unexpected action of type ${typeof func} for ${label}`);
  }

  return {
    style,
    label,
    type,
    action: (associatedPageKey, storeActions) => {
      // Applying timeout here seems less error prone than setting it in each menu/*MenuItem.js
      window.setTimeout(() => {
        func(storeActions);
      }, config.DELAY_BEFORE_CLICK_ACTION);
    },
    options: entryOptions,
  };
}

export const createItem = (style, label, action, entryOptions) =>
  _itemFactory(style, label, TYPES.ACTION, action, entryOptions);

export const createItemRoute = (style, label, pageKey, options, entryOptions, historyAction) =>
  _itemFactory(
    style,
    label,
    TYPES.ROUTE,
    () => {
      getBindedActions().navigate(pageKey, options, historyAction ? historyAction : HISTORY_ACTIONS.REPLACE);
    },
    entryOptions
  );

export const createItemToggleLocation = (style, label) =>
  _itemFactory(style, label, TYPES.TOGGLE_LOCATION, () => {}, null);
export const createItemTogglePMR = (style, label) =>
  _itemFactory(style, label, TYPES.TOGGLE_PMR, () => {}, null);
