import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { getItemTogglePMR } from 'data/config/menuConfig';

import { ITINERARY_PAGE_KEY } from 'src/pages/pagesKeys';

import BackButton from 'src/components/back-button/BackButton';
import TogglePMRItem from 'src/components/menu/TogglePMRItem';

import QRCode from 'src/components/qr-code';

import { getBindedActions } from 'src/store/bindedActions';
import projectConfiguration from '../../../../data/config.js';
import { getTerminalPOI } from 'src/pages/mobigeo/mobigeoUtil.js';
import DetailModal from 'src/components-standalone/detail-modal/DetailModal.js';
import GenericIcon from 'src/components/generic-icon/GenericIcon.js';

const itineraryPage = (props) => {
  const {
    item,
    dataType,
    children,
    childSubComponent: ChildSubComponent,
    labels,
    isPMREnabled,
    mapLoaded,
    customKeyboardIsOpen,
    ...rest
  } = props;
  const { id, original_id } = item;
  const [showDescription, setShowDescription] = useState(false);
  const [hasItinerary, setHasItinerary] = useState(true);
  const [urlQrCode, setUrlQrCode] = useState(null);
  // setScrollableContentEl = (el) => {
  //   contentContainerEl = el;
  // };
  // scrollTo = (value) => {
  //   if (contentContainerEl) {
  //     contentContainerEl.scrollTop = value;
  //   }
  // };
  useEffect(() => {
    const hasPlaces = Array.isArray(item.references.places) && item.references.places.length > 0;
    const { places } = item.references;
    const POI = {
      id,
      originalId: original_id,
      type: dataType,
    };
    if (hasPlaces) {
      POI.placeId = places[0].place_id;
      // FIXME:
      // generate as much POI objects as there are places
      // let pois = [{id: originalId, type: entityType, placeId: placeId1}, {id: originalId, type: entityType, placeId: placeId2}, ...]
      // finally call actions.showAllPoisOnMap(pois);
    }
    setHasItinerary(!!hasPlaces);
    setShowDescription(!hasPlaces);
    makeUrlQrCode(POI);
    mapLoaded &&
      hasPlaces &&
      props.actions.showMapItineraryFromTerminal(POI, { withoutNavigate: true });
  }, [isPMREnabled, mapLoaded]);

  useEffect(() => {
    customKeyboardIsOpen && actionBtnClose();
  }, [customKeyboardIsOpen]);

  const pmrToggle = (
    <TogglePMRItem
      item={item}
      dataType={props.dataType}
      label={props.labels.menu.pmrQuestion2}
      entry={{ ...getItemTogglePMR(props.labels) }}
    />
  );

  const makeUrlQrCode = (destPOI) => {
    const start = simplifyPOI(getTerminalPOI());
    const end = destPOI;
    const finalUrlQrCode = encodeURI(
      `https://${
        projectConfiguration.web.redirectUrlQrCode
      }/?/map&start=${start}&end=${JSON.stringify(end)}${isPMREnabled ? '&pmr=true' : ''}`
    );

    // console.log('finalUrlQrCode', finalUrlQrCode);
    setUrlQrCode(finalUrlQrCode);
  };

  const simplifyPOI = (POI) => {
    const placePOI = POI && POI.places[0];
    return (
      POI &&
      JSON.stringify({
        floor: placePOI.floor,
        zone: placePOI.zone,
        title: ' ',
        x: placePOI.x,
        y: placePOI.y,
      })
    );
  };

  const actionBtnClose = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (!hasItinerary) {
      getBindedActions().navigateBack();
    } else {
      setShowDescription(false);
    }
  };

  return (
    <div className="detail-container">
      <div className="detail-header">
        <BackButton actions={props.actions}>
          <div className="detail-title-container">
            <span>{labels.itinerary.destination}</span>
            <span className="detail-title">{props.item.title}</span>
            <span className="detail-place">{props.placeLabel}</span>
          </div>
        </BackButton>
      </div>
      {item.description && (
        <span className="btn-expand" onPointerDown={() => setShowDescription((val) => !val)}>
          {labels.common.seeMore}
          <i className="fal fa-chevron-right" />
        </span>
      )}

      <div className="detail-body">
        {hasItinerary ? (
          <>
            <div className="iti-header-title">
              <div>
              <GenericIcon
                style={{display: "inline-block"}}
                className={'list-el-chevron chevron rotate-down black-chevron'}
              />
                {labels.placeSearch.itinerary}
              </div>
            </div>

            <div id="itinerary-container" />
            {pmrToggle}
            <div className="iti-qr-code-container">
              <span className="iti-qr-code-instruction">{labels.common.findItinerary}</span>
              {urlQrCode && (
                <QRCode url={urlQrCode} id="iti-qr-code" className="iti-qr-code showQrCode" />
              )}
            </div>
          </>
        ) : (
          <span className="iti-qr-code-instruction">{labels.itinerary.notLocalised}</span>
        )}
      </div>
      <DetailModal
        layoutChild={ChildSubComponent}
        style={!hasItinerary ? { left: 'auto' } : {}}
        show={item.description && showDescription}
        controller={actionBtnClose}
        labels={labels}
        dataType={dataType}
        item={item}
        {...rest}
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log(state[ITINERARY_PAGE_KEY]);
  return state[ITINERARY_PAGE_KEY];
};
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(itineraryPage);
