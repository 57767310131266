import { EXIT_APP } from 'src/store/actionTypes';

import { onExitApp } from './exitService';



export default ({ dispatch, getState }) => (next) => (action) => {
  const result = next(action);

  switch (action.type) {
    case EXIT_APP:
        onExitApp();
      break;
    default: 
  }

  return result;
};
