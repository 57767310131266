import _defineProperty from "/Users/macminimobilespot/mobile-spot/legacy/mspot-printemps-borne/app-react/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";

/**
 *
 * Configuration describing how 2 pages are displayed simultaneously, screen being vertically split
 *
 */
import config from 'data/config/config';
import { // AIRCRAFT_PAGE_KEY,
// EVENT_PAGE_KEY,
// EXHIBITOR_PAGE_KEY,
LIST_PAGE_KEY, MOBIGEO_PAGE_KEY // NEWPRODUCT_PAGE_KEY,
// RESTAURANT_PAGE_KEY,
// SERVICE_PAGE_KEY,
} from 'src/pages/pagesKeys.js';
import { DATA_TYPE_BRAND_CATEGORIES } from 'data/config/dataConfig';
export var FEATURE_ACTIVATED = true;
/**
 * Required min width to auto-enable this mode
 * @type {Number}
 */

export var MIN_WIDTH = 800;
export var AREA = {
  right: 'right',
  left: 'left'
};
/**
 * Default area for all pages
 */

export var TWO_COLUMNS_DEFAULT = AREA.left;
/**
 * Every page NOT declared here will rely on `TWO_COLUMNS_DEFAULT`
 */

export var PAGES_SETUP = _defineProperty({}, MOBIGEO_PAGE_KEY, AREA.right);
/**
 * Indicate in which columns a page should be displayed
 * @param  {string} pageKey
 * @return {string}
 * @see    pagesTwoColumnsConfig.AREA
 */

export var getArea = function getArea(pageKey) {
  return PAGES_SETUP[pageKey] || TWO_COLUMNS_DEFAULT;
};
/**
 * Toolbar is displayed on a single area. Define here on which one
 */

export var TOOLBAR_AREA = AREA.left;
/**
 * CONFIGURE THIS FOR EVERY PROJECT
 */

export function getSecondPage(displayedPageKey, displayedPageProps, profile) {
  var displayedPageArea = getArea(displayedPageKey); // If displayed page is on left side, then display Map on the right side

  if (displayedPageArea === AREA.left) {
    return config.getHomePage(profile);
  } else {
    return {
      pageKey: LIST_PAGE_KEY,
      props: {
        locateAll: false,
        inputs: [{
          dataType: DATA_TYPE_BRAND_CATEGORIES
        }]
      }
    };
  }
}
/**
 * Declare fiche page keys for which POI should be automatically displayed on map
 * @type {Array}
 */

export var AUTOSHOW_POI_ON_MAP = {// [AIRCRAFT_PAGE_KEY]  : true,
  // [EVENT_PAGE_KEY]     : true,
  // [EXHIBITOR_PAGE_KEY] : true,
  // [RESTAURANT_PAGE_KEY]: true,
  // [SERVICE_PAGE_KEY]   : true,
};