const LOG = false;

/**
 * On safari and firefox:
 * scroll fast, then while the momentum is ongoing update el.scrollTop value
 * -> momentum keeps going on
 *
 * This helper stops momentum, update scroll position, then make the element scrollable again.
 *
 * @param  {DOM element} el
 */
function scrollHack(el) {
  if (!el) {
    console.error('[scrollHack] missing DOM element argument');
    return null;
  }

  let timeout = null;

  function setOverflowValue(value) {
    if (LOG) {
      console.log(`[scrollHack] set -webkit-overflow-scrolling: ${value}`);
    }
    el.style.overflowY = value;
  }

  /**
   * Make the element scrollable again
   */
  function resetOverflowValue() {
    if (timeout) {
      window.clearTimeout(timeout);
    }
    timeout = window.setTimeout(_resetOverflowValue, 0);
  }

  function _resetOverflowValue() {
    setOverflowValue('scroll');
    timeout = null;
  }

  return function scrollTo(offset) {
    // Stop momentum
    // setOverflowValue('hidden');

    el.scrollTop = offset;

    // Make the element scrollable again
    resetOverflowValue();
  };
}

export default scrollHack;
