// Libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// App modules
import { getBestRessource } from 'src/core/config-json/ConfigJsonManager';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import Url from 'src/components/url/Url';
import * as actions from 'src/store/actions';
import { isIphoneX } from 'src/core/util/browser';

import './Intersticiel.scss';

export const COMPONENT_KEY = 'Intersticiel';
export const CONTAINER_DOM_ID = 'intersticiel-container';

const LOG_PREF = '[Intersticiel] ';

class Intersticiel extends Component {
  close() {
    this.props.actions.hideIntersticiel();

    if (typeof this.props.next === 'function') {
      this.props.next();
    }
  }

  /**
   * Automatically CLOSE INTERSTICIEL after configured DELAY
   */
  applyAutoCloseTimeout() {
    let delay;
    if (this.props.appIsBooting) {
      delay = Number(this.props.config.firstTimer);
    } else {
      delay = Number(this.props.config.foregroundTimer);
    }

    if (process.env.NODE_ENV !== 'production') {
      delay = 1;
    }

    if (delay) {
      if (!this.autoCloseTimeout) {
        this.autoCloseTimeout = window.setTimeout(() => {
          if (this.props.isDisplayed) {
            this.props.actions.hideIntersticiel();
          }
          this.autoCloseTimeout = null;
        }, delay * 1000);
      }
    }
  }

  /**
   * Automatically SHOW CLOSE BUTTON after configured DELAY
   */
  applyShowCloseButtonTimeout() {
    if (this.props.config.closeBtnTimer > 0) {
      if (!this.showCloseBtnTimeout) {
        this.showCloseBtnTimeout = window.setTimeout(() => {
          if (this.props.isDisplayed && !this.props.isCloseButtonVisible) {
            this.props.actions.showIntersticielCloseButton();
          }
          this.showCloseBtnTimeout = null;
        }, this.props.config.closeBtnTimer * 1000);
      }
    } else {
      this.props.actions.showIntersticielCloseButton();
    }
  }

  /**
   * The point is to create a new object only if value is different,
   * to avoid useless renders
   * @return {object}
   */
  getCloseSubContainerStyle(conf) {
    const bgColor = conf.btnTextBgColor;

    if (!this._closeSubContainerStyle || this._closeSubContainerStyle.backgroundColor !== bgColor) {
      this._closeSubContainerStyle = {
        backgroundColor: bgColor,
      };
    }
    return this._closeSubContainerStyle;
  }

  /**
   * The point is to create a new object only if value is different,
   * to avoid useless renders
   * @return {object}
   */
  getImageStyle(resource) {
    if (
      !this._imageStyle ||
      this._imageStyle.width !== resource.width ||
      this._imageStyle.height !== resource.height
    ) {
      this._imageStyle = {
        width: resource.width,
        height: resource.height,
      };
    }
    return this._imageStyle;
  }

  render() {
    return null;
  }
}

Intersticiel.propTypes = {
  isDisplayed: PropTypes.bool.isRequired,
  isCloseButtonVisible: PropTypes.bool.isRequired,
  labels: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  config: PropTypes.object,
  files: PropTypes.object,
  actions: PropTypes.object.isRequired,
  next: PropTypes.func,
  appIsBooting: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => state[COMPONENT_KEY];
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(Intersticiel);
