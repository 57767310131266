import React from 'react';
import PropTypes from 'prop-types';

import './GenericIcon.scss';

const GenericIcon = ({ style, title, className, onClick }) => (
  <span onClick={onClick} title={title} className={'gi '+ className} onClick={onClick} style={style} />
);

GenericIcon.defaultProps = {
  className: 'gi',
  onClick: () => {},
  style: {},
  title: '',
};

GenericIcon.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  title: PropTypes.string,
  style: PropTypes.object,
};

export default GenericIcon;
